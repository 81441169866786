/* tabpanel */
$(function() {
    $('.Tabs').each(function(i, tab) {
        var tabTriggers = $('.Tabs').find('.TabTrigger');
        var tabContents = $('.Tabs').find('.TabContent');


        // Apply ARIA and display values on load
        if (tabTriggers.hasClass('__active')) {
        } else {
            tabContents.hide();
        }


        tabTriggers.click(function(e) {
            e.preventDefault();
            var dataSet = $(this).data('set');

            $('.TabTrigger.__active').removeClass('__active');
            $(this).addClass('__active');

            $('.TabContent.__active').removeClass('__active');
            $('.TabContent[data-set="' + dataSet + '"]').addClass('__active');
        });
    });
});
