require('./modules/accordion');
require('./modules/animatein');
require('./modules/bannerVideo');
require('./modules/formInputs');
require('./modules/menu');
require('./modules/modal');
require('./modules/scrollTo');
require('./modules/searchToggle');
require('./modules/shareSocial');
require('./modules/sideMenu');
require('./modules/tabs');
