/* radiobuttons */
$(document).on('change', '.__radiobuttons input', function() {
    var groupName = $(this).attr('name');
    $('input[name="' + groupName + '"]').parent().removeClass('__checked');
    $(this).parent().addClass('__checked');
});

$('.__radiobuttons input:checked')
    .parent()
    .addClass('__checked');
$('.__radiobuttons input:checked').parents('.card-journey').addClass('__active');

/* checkboxes */
$(document).on('change', '.__checkboxes input', function() {
    if ($(this).is(':checked')) {
        $(this)
            .parent()
            .addClass('__checked');
    } else {
        $(this)
            .parent()
            .removeClass('__checked');
    }
});
$('.__checkboxes input:checked')
    .parent()
    .addClass('__checked');
